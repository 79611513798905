export const Homedata = [
    {
      title: "Integrated Approach",
      description: "We believe in the synergy between physiotherapy and fitness. Our integrated approach combines therapeutic techniques with tailored exercise programs to not only address existing injuries but also prevent future ones and enhance overall well-being.",
    },
    {
      title: "Expert Guidance",
      description:"Our team of experienced physiotherapists and fitness professionals work together to provide personalized care. Whether you're recovering from an injury, managing a chronic condition, or simply seeking to improve your fitness level, our experts will guide you every step of the way."
    },
    {
      title: "Customized Programs",
      description: "We understand that each individual has unique needs and goals. That's why we create customized treatment and exercise plans tailored to your specific requirements. Whether you're an athlete aiming for peak performance or someone looking to improve mobility and function, we have a program for you.",
    },
    {
      title: "Accessible Location",
      description: "Located in a convenient and accessible area, our clinic is easily reachable, making it hassle-free for you to attend your appointments and stay consistent with your treatment and exercise regimen.",
    }
  ];
  
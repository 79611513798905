import React from 'react'
import Navbar from '../components/Navbar'
import banner from '../assets/logo.jpeg'
import { Homedata } from '../data/Homedata'
import Treatment from '../components/Treatment'
import Intro from '../components/Intro'
import whatspp from '../assets/whatsapp.png'
function Home() {
  return (
    <div className='md:px-12 p-4 max-w-screen-2xl  mx-auto mt-20 '>
      <div className='gradientBg rounded-xl rounded-tl-[80px] rounded-br-[80px] md:p-9 px-4 py-9'>
        <div className='flex flex-col md:flex-col xl:flex-row-reverse justify-between items-center gap-10' >

          {/* image */}
          <div>
            <img src={banner} className='lg:h-[386px] h-[200px] rounded-full' alt="" />
          </div>

          <div className='md:w-3/5  '>
            {/* content */}
            <h2 className='md:text-7xl text-3xl font-bold text-white mb-6 leading-relaxed'>Explore the World of Physiotherapy </h2>
            <p className='text-[#EBEBEB] text-xl mb-8'>Welcome to <span className='text-[#103559] lg:text-[#2abed3] font-semibold'>Physio Fitness Clinic</span>, your gateway to understanding and experiencing the transformative benefits of physiotherapy. We believe in empowering individuals to reclaim their health and vitality through personalized care and expert guidance.</p>
          </div>


        </div>
      </div>

      <div className='mt-20'>
        <Intro />
      </div>

      {/* Treatment section */}
      <Treatment />
      
      <div class="flex items-end justify-end fixed bottom-0 right-0 mb-4 mr-4 z-10">
        <div>
          <a title="Contact us" href="https://t.ly/0X1u3" target="_blank" class="block w-16 h-16 rounded-full transition-all shadow hover:shadow-lg transform hover:scale-110 hover:rotate-12">
            <img class="object-cover object-center w-full h-full rounded-full" src={whatspp} />
          </a>
        </div>
      </div>

    </div>
  )
}

export default Home
import logo from './logo.svg';
import './App.css';
import Home from './pages/Home';
import { Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import About from './pages/About';
import Services from './pages/Services';
import Testimonials from './pages/Testimonials';
import Contact from './pages/Contact';
import Footer from './components/Footer';
function App() {
  return (
    <div>
      <Navbar></Navbar>
      <Routes>
        <Route path='/' element={<Home/>}>
        </Route>

        <Route path='/about' element={<About/>}>
        </Route>

        <Route path='/services' element={<Services/>}>
        </Route>

        <Route path='/testimonial' element={<Testimonials/>}>
        </Route>

        <Route path='/contact-us' element={<Contact/>}>
        </Route>
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;

import React from 'react'
import Hero from '../components/Hero'
import banner from '../assets/pic/bn1.jpeg'
import { AiOutlineMail } from "react-icons/ai";
import { AiFillPhone } from "react-icons/ai";
import { ImLocation2 } from "react-icons/im";
import logo from '../assets/logo3.png'
import { ContactUs } from '../components/ContactUs';
import whatspp from '../assets/whatsapp.png'
const Contact = () => {
  const title = "Contact"
  return (
    <div>
      <Hero title={title} banner={banner} />
      <div className='mt-20 mb-20  flex items-center justify-around flex-col md:flex-col xl:flex-row gap-20'>
        <div className='flex items-center justify-center p-6 flex-col md:flex-row gap-6' >
          <div><img src={logo} className='w-[150px] md' alt="" /></div>

          <div className='flex flex-col gap-5'>
            <div>
              <div className='flex gap-3 items-center text-sm md:text-xl'>
              <AiFillPhone/>
              <p>Clinic:</p>
              </div>
              <p className='text-sm md:text-xl'>9999702952</p>
            </div>

            <div>
            <div className='flex gap-3 items-center text-sm md:text-xl'>
              <AiOutlineMail/>
              <p>Email:</p>
              </div>
              <p className='text-sm md:text-xl'>physiofitnessclinic2024@gmail.com</p>
            </div>

            <div>
            <div className='flex gap-3 items-center text-sm md:text-xl'>
              <ImLocation2/>
              <p>Address:</p>
              </div>
              <p className='text-sm md:text-xl'>H.No:-307,Sector 65, Faridabad, Haryana (121005)</p>
            </div>
          </div>
        </div>

        <div>
          {/* <ContactUs/> */}
        </div>
      </div>
      <div class="flex items-end justify-end fixed bottom-0 right-0 mb-4 mr-4 z-10">
        <div>
          <a title="Contact Us" href="https://t.ly/0X1u3" target="_blank" class="block w-16 h-16 rounded-full transition-all shadow hover:shadow-lg transform hover:scale-110 hover:rotate-12">
            <img class="object-cover object-center w-full h-full rounded-full" src={whatspp} />
          </a>
        </div>
      </div>
    </div>
  )
}

export default Contact
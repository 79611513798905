import Taping from '../assets/Thearpy/Taping.jpg'
import neddling from '../assets/Thearpy/acupuncture.jpg'
import Cupping from '../assets/Thearpy/cupping.jpg'
import Manual from '../assets/Thearpy/manual.jpg'
import pilates from '../assets/Thearpy/pilates.png'
import chiro from '../assets/pic/bn3.jpeg'
import rehab from '../assets/Thearpy/rehab.jpg'
import pt5 from '../assets/pic/bn2.jpg'
import pt6 from '../assets/LogoTreatment/pt6.png'
//import Taping from '../assets/LogoTreatment/Taping.png'



//<a href="https://www.flaticon.com/free-icons/physiotherapy" title="physiotherapy icons">Physiotherapy icons created by Freepik - Flaticon</a>
export const ThearpyData = [
    {
      img:neddling,
      title: "Dry Needling",
      description: "Dry needling is a technique used to treat musculoskeletal pain and dysfunction. Fine needles are inserted into trigger points or tight bands of muscles to elicit a local twitch response, which helps release tension and improve blood flow to the affected area, leading to pain relief and improved mobility.",
    },
    {
      img:Cupping,
      title: "Cupping Therapy",
      description:"Cupping therapy involves placing cups on the skin and creating suction to facilitate healing and relieve muscle tension. It can help reduce pain, inflammation, and promote relaxation by increasing blood circulation and stimulating the body's natural healing processes."
    },
    {
      img:Manual,
      title: "Manual Therapy",
      description: "Manual therapy encompasses a variety of hands-on techniques performed by physiotherapists or chiropractors to manipulate soft tissues and joints. It includes techniques such as joint mobilization, soft tissue massage, stretching, and manipulation to reduce pain, improve flexibility, and restore normal function.",
    },
    {
      img:pilates,
      title: "Pilates",
      description: "Pilates is a form of exercise that focuses on core strength, flexibility, and body awareness. It involves a series of controlled movements and exercises performed on a mat or specialized equipment, designed to improve posture, balance, and overall physical fitness.",
    },
    {
        img:rehab,
        title: "Rehabilitation",
        description: " Rehabilitation involves a structured program of exercises, stretches, and therapies designed to restore function and mobility following injury, surgery, or illness. It aims to strengthen muscles, improve flexibility, and enhance coordination to facilitate a safe return to daily activities and sports.",
    },
    {
        img:pt5,
        title: "Physiotherapy",
        description: " Physiotherapy is a healthcare profession focused on helping individuals recover from injury, manage pain, and improve physical function. Physiotherapists use a combination of manual therapy, exercise prescription, modalities, and patient education to promote healing and optimize overall well-being",
    },
    {
        img:chiro,
        title: "Chiropractic Alignment",
        description: " Chiropractic alignment, also known as chiropractic adjustment or manipulation, involves the manual manipulation of the spine and other joints to correct misalignments, improve joint function, and alleviate pain. It is commonly used to treat conditions such as back pain, neck pain, and headaches. ",
    },
    {
        img:Taping,
        title: "Taping",
        description: " Taping involves applying adhesive tape to the skin to support muscles, stabilize joints, and reduce pain and inflammation. It can help improve posture, enhance athletic performance, and provide temporary relief from injuries by providing external support and proprioceptive feedback.",
    },
  ];
  
  
export const ReviewData = [
    {
        name:"Nikhil Rohilla",
        desc: "I had severe back pain when I came here even though I was not able to walk but after meeting Dr jyotsana he gave me the trust that I am on the right hand and I recovered 90% on the same day , Within two sittings I am completely recovered,10 out of 10 from my end ",
        link: "https://g.co/kgs/La9NTH9"

    },
    {
        name:"Jyoti Dhruv",
        desc: "I had the pleasure of being treated by Dr. Jyotsana, and I must say, her professionalism and expertise are unmatched. She provided attentive care tailored to my needs, creating a comfortable and effective rehabilitation experience. I highly recommend her services to anyone seeking top-notch physiotherapy treatment. ",
        link:"https://g.co/kgs/DXnk9P3"

    },
    {
        name:"Puja Panchal",
        desc: "Holistic treatment with great efforts.Highly recommended Dr. Jyotsana( physio fitness clinic) good job🤟 ",
        link:"https://g.co/kgs/jvKKwM6"

    },
    {
        name:"Abby KAt",
        desc: "Excellent rehab therapy for people who have pain, or discomfort of any kind. The doctor is extremely caring and professional, not money-minded. She puts in her best for patients' recovery!! Thank You Doctor 🙏🏻 ",
        link:"https://g.co/kgs/h1tNj8D"

    },
    {
        name:"Nisha Arora",
        desc: "I HAD(focussed because I was in lot pain) neck pain due to my sitting job from last 6 months which was treated after visiting Dr. Jyotsana mam in 10 sittings . I would recommend her to anybody who wants a physio chiro and an osteopath . . Thanku mam☺ ",
        link:"https://g.co/kgs/27HhVT7",

    },
    {
        name:"Vishesh Kejriwal",
        desc: "Meet Dr. Jyotsna, your compassionate healing expert in physiotherapy. Experience her exceptional care and innovative treatments that bring results. Trust in the hands that healbook your session now and take the first step towards a pain-free life! ",
        link:"https://g.co/kgs/NjjMdsn",
    },
    {
        name:"Hardeep Dhruv",
        desc: "Dr. Jyotsana is a highly skilled and experienced professional in the field of physiotherapy. Her expertise is evident in her thorough assessments, personalized treatment plans, and effective rehabilitation techniques. Whether it's addressing musculoskeletal issues, sports injuries, or chronic pain management, she approaches each case with precision and dedication. Her commitment to staying updated with the latest advancements in physiotherapy ensures that her patients receive the highest quality care possible. If you're seeking a physiotherapist with unparalleled expertise, Dr. Jyotsana is the one to trust for your rehabilitation needs. ",
        link:"https://g.co/kgs/s2bmWk4",

    },
];
import React from 'react'
import banner from '../assets/pic/bn13.jpeg'
import Hero from '../components/Hero'
import whatspp from '../assets/whatsapp.png'
import { ReviewData } from '../data/ReviewData'
import ReactPlayer from 'react-player'
const Testimonials = () => {

  const title = "Testimonials"

  return (
    <div>
      <Hero title={title} banner={banner} />
      <div className='mt-20 flex flex-col items-center gap-3'>

        <div class="text-center max-w-xl mx-auto">
          <h1 class="text-4xl  md:text-7xl font-bold mb-5 text-[#145DA0]">What people <br />are saying.</h1>
          <h3 class="text-xl mb-5 font-light">Reviews</h3>
          <div class="text-center mb-10">
            <span class="inline-block w-1 h-1 rounded-full bg-[#145DA0] ml-1"></span>
            <span class="inline-block w-3 h-1 rounded-full bg-[#145DA0] ml-1"></span>
            <span class="inline-block w-40 h-1 rounded-full bg-[#145DA0]"></span>
            <span class="inline-block w-3 h-1 rounded-full bg-[#145DA0] ml-1"></span>
            <span class="inline-block w-1 h-1 rounded-full bg-[#145DA0] ml-1"></span>
          </div>
        </div>
        <div className='bg-black '></div>
        <div className='grid grid-cols-1 md:grid-cols-1 lg:grid-cols- rounded-xl gap-6 '>
          {
            ReviewData.map((items, index) => {
              return (
                <div className=' transition-all duration-700 bg-white rounded-xl shadow-2xl border-2 border-gray-400 p-5 w-[80%] md:w-[50%] mx-auto flex flex-col' key={index}>
                  <div className='rounded-xl overflow-hidden'><img width={50} src={items.img} alt="" /></div>
                  <div>
                    <h1 className='text-2xl md:text-3xl text-[#145DA0] font-medium mt-3'>{items.name}</h1>
                    <p className='text-slate-500 text-lg mt-3'>{items.desc}</p>
                    <a href={items.link} target='_blank' >
                      <button className='mt-4 bg-[#103559] text-white p-3 font-bold rounded-lg hover:bg-[#2abed3] transition-all duration-200'>Read Full </button>
                    </a>
                  </div>
                </div>
              )
            })
          }
        </div>
        <h1 class="text-4xl mt-7 md:text-7xl font-bold mb-5 text-[#145DA0]">Video Testimonials</h1>
        <div className=''>
          <ReactPlayer url='https://www.youtube.com/shorts/GSV_doUzFgE' width="300px" height="500px"  ></ReactPlayer>
        </div>
      </div>
      <div class="flex items-end justify-end fixed bottom-0 right-0 mb-4 mr-4 z-10">
        <div>
          <a title="Contact Us" href="https://t.ly/0X1u3" target="_blank" class="block w-16 h-16 rounded-full transition-all shadow hover:shadow-lg transform hover:scale-110 hover:rotate-12">
            <img class="object-cover object-center w-full h-full rounded-full" src={whatspp} />
          </a>
        </div>
      </div>

          

    </div>
  )
}

export default Testimonials

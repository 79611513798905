import React from 'react'
import banner from '../assets/pic/bn10.jpg'
import Hero from '../components/Hero'
import Treatment from '../components/Treatment'
import Thearpy from '../components/Thearpy'
const Services = () => {
  const title= "Services"
  return (
    <div className=''>
      <Hero title={title} banner={banner} ></Hero>
      <div className='p-7 xl:p-[100px] md:p-[100px]' >
        <Thearpy/>
      </div>
    </div>
  )
}

export default Services
import React from 'react'
import logo4 from '../assets/logo3.png'
import { Homedata } from '../data/Homedata'
const Intro = () => {
  return (
    <div className='md:px-14 p-4 max-w-s mx-auto bg-gray-100 rounded-lg'>
        <div className='flex flex-col md:flex-col xl:flex-row justify-between items-center gap-8'>
            <div className='md:w-1/2'>
                <img src={logo4} alt="" />
            </div>

            <div className='flex flex-col items-center '>
                <h2 className='md:text-5xl text-3xl font-bold text-primary mb-5 text-[#2abed3] leading-normal'>Why To Reach Us ?</h2>
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 '>
                {
                    Homedata.map((items,index)=>{
                        return(
                            <div className='p-5 flex flex-col' key={index}>
                                <h1 className='text-2xl md:text-3xl font-medium mt-3'>{items.title}</h1>
                                <p className='text-slate-500 text-lg mt-3'>{items.description}</p>
                            </div>
                        )
                    })
                }
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default Intro
import React from 'react'
import logo from '../assets/logo3.png'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';
import { Link, matchPath } from 'react-router-dom'
//import { NavbarLinks } from '../data/Navbarlinks'
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
function Navbar() {

    const NavbarLinks = [
        {
            title: "Home",
            path: "/",
        },
        {
            title: "Staff",
            path: "/about"
        },
        {
            title: "Services",
            path: "/services",
        },
        {
            title: "Testimonials",
            path: "/testimonial",
        },
        {
            title: "Contact",
            path: "/contact-us",
        },
    ];

    const location = useLocation();

    const matchRoute = (route) => {
        return matchPath({ path: route }, location.pathname);
    }

    const [nav, setNav] = useState(false);

    // Toggle function to handle the navbar's display
    const handleNav = () => {
        setNav(!nav);
    };


    return (
        <div className=' flex h-20 items-center justify-evenly border-b-[2px] border-b-richblack-700'>
            <div className='flex w-11/12 max-w-maxContent items-center justify-between'>

                {/* Logo */}
                <div className='flex  items-center justify-center gap-2'>
                    <Link to="/">
                        <img src={logo} className='w-[55px]  md:w-[60px] xl:w-[70px]' loading='lazy'  />
                    </Link>
                    <Link to='/'>
                        <p className='text-[#103659] text-[20px] md:text-[15px] xl:text-[30px] font-bold  font-inter '>Physio Fitness Clinic</p>
                    </Link>
                </div>

                {/* Desktop Navigation */}
                <ul className='hidden md:flex'>
                    {NavbarLinks.map(item => (
                        <li
                            key={item.id}
                            className='p-2 rounded-xl m-2 cursor-pointer duration-300 hover:text-black'>

                            <Link to={item?.path}>
                                <p className={`${matchRoute(item?.path) ? " text-[#2abed3] transtion-all duration-300 text-sm xl:text-xl font-semibold " : "font-semibold text-slate-500	 relative text-sm xl:text-xl w-fit block after:block after:content-[''] after:absolute after:h-[3px] after:bg-[#2abed3] after:w-full after:scale-x-0 after:hover:scale-x-100 after:transition after:duration-300 after:origin-center"}`}>
                                    {item.title}
                                </p>
                            </Link>
                        </li>
                    ))}
                </ul>

                {/* Mobile Navigation Icon */}
                <div onClick={handleNav} className='block md:hidden'>
                    {nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={23} />}
                </div>

                {/* Mobile Navigation Menu */}
                <ul
                    className={
                        nav
                            ? 'fixed md:hidden bg-white z-[1] left-0 top-0 w-[80%] h-full border-r border-r-gray-900 ease-in-out duration-300 overflow-scroll	'
                            : 'ease-in-out w-[100%] duration-300 fixed top-0 bottom-0 left-[-100%]'
                    }
                >
                    {/* Mobile Logo */}
                    <div className='flex items-center justify-center gap-4'>
                    <Link to="/">
                        <img src={logo} width={80} loading='lazy' />
                    </Link>
                    
                </div>

                    {/* Mobile Navigation Items */}
                    {NavbarLinks.map(item => (
                        <li
                            key={item.id}
                            className='p-4  rounded-xl duration-300 hover:text-black cursor-pointer border-gray-600'
                        >
                            <Link to={item?.path} onClick={handleNav}>
                                <p className={`${matchRoute(item?.path) ? "  text-[#2abed3]  transtion-all duration-300 text-xl" : "text-black text-xl"}`}>
                                    {item.title}
                                </p>
                            </Link>
                        </li>
                    ))}
                </ul>

            </div>
        </div>
    )
}

export default Navbar


import React from 'react'
import  {TreatmentData}  from '../data/TreatmentData'
function Treatment() {
    return (
        <div className='mt-20 flex flex-col items-center gap-3'>
            <h1 className='text-[#2abed3] text-5xl font-semibold '>Treatments</h1>
            <div className='bg-black '></div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 rounded-xl gap-6 '>
                {
                    TreatmentData.map((items, index) => {
                        return (
                            <div  className=' hover:bg-blue-100 transition-all duration-700 bg-white rounded-xl shadow-2xl p-5 flex flex-col' key={index}>
                                <div className='rounded-xl overflow-hidden'><img width={50} src={items.img} alt="" /></div>
                                <div>
                                    <h1 className='text-2xl md:text-3xl text-[#145DA0] font-medium mt-3'>{items.title}</h1>
                                    <p className='text-slate-500 text-lg mt-3'>{items.description}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Treatment
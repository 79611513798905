import React from 'react'
//import banner from '../assets/pic/bn10.jpg'
const Hero = ({title,banner}) => {
  return (
    <div className='bg-gradient-to-r from-[black] '>
        <section id='hero' className='w-[100%] h-[600px] m-auto  bg-cover opacity-75 bg-center  flex justify-center flex-col items-center text-center  lg:px-28 px-10' style={{ backgroundImage: `url(${banner})`}}>
            <h1 className='md:text-6xl text-[#ffffff] font-semibold  pr-0 md:lg:leading-[70px] md:leading-[60px]  '> <span className='text-[#103559] text-5xl '>{title}</span>  </h1>
        </section>
        
    </div>
  )
}

export default Hero
import React from 'react'
import banner from '../assets/logo3.png'
import Intro from '../components/Intro'
const About = () => {
  return (
    <div className='    max-w-screen  mx-auto    '>

      <div className=' flex items-center w-full justify-center mb-20 bg-blue-50 py-12 xl:pt-12 xl:pb-12 overflow-hidden'>
        <p className='m-5 md:text-7xl text-4xl font-bold text-[#103559] mb-6 leading-relaxed'>About</p>
      </div>

      <div className='flex shadow-xl flex-col md:flex-col  xl:flex-row-reverse my-20 gap-10 xl:gap-10 w-[85%] mx-auto  items-center  p-5'>
        {/*left*/}
        <div className=''>
          <img src={banner} className="md:w-[400px]" />
        </div>

        {/*right*/}
        <div className='    flex flex-col  gap-4'>
          <h1 className='md:text-6xl text-3xl font-bold text-[#335780]  leading-relaxed'>Dr Jyotsana (PT)</h1>
          <p className='md:w-[50%] xl:w-[50%] text-[#103559] font-bold text-xl mb-8'>BPT. MPT(ORTHO)
            MIAP (Member of Indian Association of Physiotherapy) , CMT (Certified in Manual therapy) , MMTFI(Member of Manual Therapy Foundation of India)</p>
          <p className=' md:w-[50%] xl:w-[50%] text-gray-500 text-xl mb-8'>Dr.Jyotsana, is a highly experienced physiotherapist trained in physical therapy and has worked at the Escorts Fortis, Faridabad and Raj Hospital as a consultant senior physiotherapist and Senior consultant at Healing Hands Sec-14,17.</p>
        </div>
      </div>

      <div className='bg-[#335780]   w-[85%] md:w-[70%] xl:w-[50%] mx-auto px-6 py-6 rounded-lg shadow-xl mb-8'>
        <div>
          <h1 className='text-white'>E-mail:</h1>
          <p className='text-white font-semibold'>physiofitnessclinic2024@gmail.com</p>
        </div>
        <div>
          <h1 className='text-white'>Contact Number:</h1>
          <p className='text-white font-semibold'>9999702952</p>
        </div>
      </div>


    </div>

  )
}

export default About
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import toast, { Toaster } from 'react-hot-toast';





export const ContactUs = () => {
  const form = useRef();
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm('service_jofnqtg', 'template_s0nca3q', form.current, {
        publicKey: '27VwOQJdsC-nM_ySw',
      })
      .then(
        () => {
          console.log('SUCCESS!');
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
     
      toast.success("Form Submitted Successfully")
       
     
  };



  return (
     <div>
        <div class="max-w-lg mx-auto bg-white 
                rounded-md shadow-md p-6">
        <h1 class="text-2xl font-semibold 
                   text-gray-800 mb-4 text-center">
              Contact Us
          </h1>
        <form id="contactForm" ref={form} onSubmit={sendEmail}>
            <div class="mb-4">
                <label for="user_name"
                       class="block text-sm font-medium text-gray-700">
                      Name
                  </label>
                <input type="text" id="name" name="user_name"
                       class="form-input mt-1  border-2 p-1 rounded-lg block w-full"
                       placeholder="Enter your name" required/>
            </div>
            <div class="mb-4">
                <label for="user_email"
                       class="block text-sm font-medium text-gray-700">
                      Email
                  </label>
                <input type="email" id="email" name="user_email"
                       class="form-input mt-1 border-2 p-1 rounded-lg block w-full"
                       placeholder="Enter your email" required/>
            </div>
            <div class="mb-4">
                <label for="message"
                       class="block text-sm font-medium 
                              text-gray-700">
                      Message
                  </label>
                <textarea id="message" name="message"
                          class="form-textarea mt-1  border-2 p-1 rounded-lg block w-full" rows="6"
                          placeholder="Enter your message" required>
                  </textarea>
            </div>
            <div class="flex items-center justify-between">
                <button  type="submit"
                        class="px-4 py-2 bg-blue-500 text-white 
                               rounded hover:bg-blue-900">
                      Submit
                  </button>
                <button type="reset" 
                
                    class="px-4 py-2 bg-gray-500 text-white 
                           rounded hover:bg-gray-600">
                      Reset
                  </button>
                  
            </div>
        </form>
        <p id="submitMessage"
           class="text-green-500 font-semibold mt-2 hidden">
              Form submitted successfully!
          </p>
    </div>
      
     </div>
  );
};
import React from 'react'
import { ThearpyData } from '../data/ThearpyData'
const Thearpy = () => {
    return (
        <div className='mt-20 flex flex-col items-center justify-center gap-3'>
            <h1 className='text-[#103559] text-2xl font-semibold '>Physio Fitness provide a range of physiotherapy services, including:
</h1>
            <div className='bg-black '></div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 rounded-xl gap-6 '>
                {
                    ThearpyData.map((items, index) => {
                        return (
                            
                            <div key={index} class="max-w-2xl mx-auto">
    
                            <div class=" shadow-md border border-gray-200 rounded-lg max-w-sm h-[100%]  bg-[#103559] ">
                                <a href="#">
                                    <img class="rounded-t-lg" src={items.img} alt=""/>
                                </a>
                                <div class="p-5">
                                    <a href="#">
                                        <h5 class="text-gray-900 font-bold text-2xl tracking-tight mb-2 dark:text-white">{items.title}</h5>
                                    </a>
                                    <p class="font-normal text-gray-700 mb-3 dark:text-gray-400">{items.description}</p>
                                    <a href="#" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center inline-flex items-center  dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        Read more
                                    </a>
                                </div>
                            </div>
                        
                            
                        </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Thearpy

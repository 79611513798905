// import React from 'react'
 import logo from '../assets/logo.jpeg'
 import { FaYoutube } from "react-icons/fa6";
 import { FaInstagram } from "react-icons/fa";
 import { FaTwitter } from "react-icons/fa";
 import { IoLocationSharp } from "react-icons/io5";
 import { AiOutlineMail } from "react-icons/ai";
 import { AiFillPhone } from "react-icons/ai";
 import { ImLocation2 } from "react-icons/im";
// const Footer = () => {
//   return (
//     // <div className='bg-[#103559] md:px-14 p-4 max-w-screen-3xl flex items-center  mx-auto text-white'>
//     //     <div className='my-9 flex flex-col md:flex-row gap-10'>
//     //         <div className='md:w-1/2 space-y-8'>
//     //             <a href="/" className='text-2xl font-semibold flex items-center space-x-3 text-primary'>
//     //                 <img src={logo} className='w-[100px] rounded-full inline-block items-center' alt="" />
//     //                 <span className='text-white '>Physio Fitness Clinic</span>
//     //             </a>
//     //             <p className='md:w-1/2'>In Motion, In Health: Elevate Your Well-being with Physiotherapy.</p>
//     //         </div>
            

//     //         <div className='md:w-1/2 flex flex-col md:flex-row flex-wrap justify-between gap-9 items-start'>
//     //             <div className='space-y-4 mt-5'>
//     //                 <h4 className='text-xl'>Platform</h4>
//     //                 <ul className='space-y-3'>
//     //                     <a href="/" className='block hover:text-gray-300'>Sample</a>
//     //                     <a href="/" className='block hover:text-gray-300'>Sample</a>
//     //                     <a href="/" className='block hover:text-gray-300'>Sample</a>
//     //                     <a href="/" className='block hover:text-gray-300'>Sample</a>
//     //                 </ul>
//     //             </div>

//     //             <div className='space-y-4 mt-5'>
//     //                 <h4 className='text-xl'>Help</h4>
//     //                 <ul className='space-y-3'>
//     //                     <a href="/" className='block hover:text-gray-300'>Sample</a>
//     //                     <a href="/" className='block hover:text-gray-300'>Sample</a>
//     //                     <a href="/" className='block hover:text-gray-300'>Sample</a>
//     //                     <a href="/" className='block hover:text-gray-300'>Sample</a>
//     //                 </ul>
//     //             </div>


//     //             <div className='space-y-4 mt-5'>
//     //                 <h4 className='text-xl'>Contact</h4>
//     //                 <ul className='space-y-3'>
//     //                     <p className='hover:text-gray-300'>12345678</p>
//     //                     <p className='hover:text-gray-300'>FDb</p>
//     //                     <p className='hover:text-gray-300'>Haryana</p>
//     //                     <p className='hover:text-gray-300'>Pricing</p>
//     //                 </ul>
//     //             </div>
//     //         </div>
//     //     </div>
//     // </div>


//   )
// }

// export default Footer

import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-[#103559] text-white p-10 mt-10 border-t-2 hover:border-blue-400 transition-all duration-300">
      <div className="container mx-auto flex flex-col gap-10 lg:flex-row justify-center md:justify-around items-center px-4 ">
        
        <div className="mb-4 lg:mb-0 flex flex-col md:flex-row items-center justify-center md:text-justify text-center gap-4">
            <img src={logo} className='w-[100px] md:w-[200px] rounded-full ' alt="" />
            <div>
            <h1 className="text-xl font-semibold">Physio Fitness Clinic</h1>
          <p className="mt-2">Your Trusted Physiotherapy Partner</p>
          <div className='flex items-center flex-row justify-center gap-1 mt-6'>
                <div className='flex gap-2'>
                <span className='font-bold'>Phone No :-</span>
                </div>
                <p>9999702952</p>
            </div>
            <div className='flex items-center flex-col gap-3 mt-6'>
                <div className='flex gap-2'>
                <ImLocation2 className='text-2xl' />
                </div>
                <p>Sector 65, Faridabad, Haryana (121005)</p>
            </div>

           


            </div>
        </div>

        

        <div className='flex flex-col items-center '>
          <h2 className="text-lg font-semibold">Like & Follow</h2>
          <div className="flex mt-2">
            <a href="https://www.youtube.com/@PhysioFitnessclinic" target='_blank' className="text-gray-400 text-2xl hover:text-white mr-4">
             <FaYoutube/>
            </a>
            <a href="https://www.instagram.com/physio_fitness_clinic/" target='_blank' className="text-gray-400 text-2xl hover:text-white mr-4">
             <FaInstagram/>
            </a>
            <a href="https://twitter.com/Physio2024" target='_blank' className="text-gray-400 text-2xl hover:text-white">
           
              <FaTwitter/>
            </a>

            
          </div>
          
        </div>
        
      </div>
          
      
    </footer>
  );
};

export default Footer;

